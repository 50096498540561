import { CardActionArea, CardMedia, Typography, Pagination, AppBar, Toolbar, IconButton} from '@mui/material';

import * as React from "react"

const confirmationPage = () => {
    return(
        <main>
            <Typography variant="h3">Your order did not go through successfully</Typography>
            <Typography>Please return to the main page and try again. Your card has not been charged.</Typography>
        </main>
    )

}

export default confirmationPage;